import React from 'react';
import Header from '../components/header';
import Jobs from '../components/jobs';

export default () => (
    <>
        <Header />
        <Jobs />
    </>
);
