import React from 'react';
import { ReactComponent as Cross } from '../main/img/cross.svg';
import Modal from 'react-modal';

const customStyles = (whiteBg) => ({
    overlay: { top: 74 },
    content: {
        padding: 0,
        margin: whiteBg ? '0 0 0 300px' : 0,
        top: whiteBg ? 0 : 15,
        left: 30,
        right: 30,
        border: 'none',
        background: whiteBg ? '#fff' : 'transparent',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 373,
        height: '100%',
        borderLeft: whiteBg ? '1px solid #F4F4F4' : 'none',
        borderRight: whiteBg ? '1px solid #F4F4F4' : 'none'
    }
});

export default ({ children, isOpen, closeModal, whiteBg = false }) => {
    return (
        <Modal {...{ ariaHideApp: false, isOpen, style: customStyles(whiteBg) }}>
            <Cross
                className="main-close-icon"
                onClick={closeModal}
                style={{
                    margin: whiteBg ? '18px 35px 0 0' : 0,
                    width: whiteBg ? 17 : 'auto',
                    height: whiteBg ? 17 : 'auto'
                }}
            />
            {children}
        </Modal>
    );
};
