import React from 'react';
import Header from '../components/header';
import Audit from '../components/audit';

export default () => (
    <>
        <Header />
        <Audit />
    </>
);
