import React from 'react';
import './card.scss';

export default ({ title, date, desc }) => (
    <div className="card">
        <div style={{ textAlign: 'right' }}>
            <div className="card-title">{title}</div>
            <div className="card-date">{date}</div>
        </div>
        <div className="card-desc">{desc}</div>
    </div>
);
