import React from 'react';
import { Link } from 'react-router-dom';
import './nav.scss';
import Button from '../button';
import { getContent } from '../../languages/utils';
import { LangContextConsumer } from '../../languages/langContext';

export default () => (
    <LangContextConsumer>
        {(context) => (
            <div className="mobile-nav">
                <div className="mobile-nav-group">
                    <Link to={'/consult'}>
                        <Button>{getContent(context, 'navElem1')}</Button>
                    </Link>
                    <Link to={'/job'}>
                        <Button>{getContent(context, 'navElem3')}</Button>
                    </Link>
                </div>
                <div className="mobile-nav-group">
                <Link to={'/audit'}>
                    <Button>{getContent(context, 'navElem4')}</Button>
                </Link>
                </div>
            </div>
        )}
    </LangContextConsumer>
);
