import React from 'react';
import parse from 'html-react-parser';

export default {
    ru: {
        // Personality
        name: 'Александр  Одинцов',
        prof1Logo: '💻',
        prof1: 'Руководитель разработки',
        prof2Logo: '🧔🏻',
        prof2: 'Проектирую, разрабатываю мобильные и web приложения',
        prof3Logo: '🦸',
        prof3: 'Консультирую по IT проектам и продуктам',
        place: 'Москва, Россия',
        phoneTitle: 'телефон',
        mailTitle: 'электропочта',
        // Nav
        navElem1: 'Консультации',
        navElem2: 'Я.Метрика и G.Analytics',
        navElem3: 'Работа',
        navElem4: 'Аудит',
        // Captions
        caption1: 'Онлайн консультации',
        caption2: 'Я.Метрика и G.Analytics',
        caption3: 'Сейчас',
        caption4: 'Ранее',
        caption5: 'Аудит',
        captionBuy: 'Заказать',
        captionBack: '< назад',
        priceAnalytics: parse('<span>от 31000&#8381;</span>'),
        priceConsultBefore: parse(''),
        priceConsult: parse('<span>9000&#8381;/час</span>'),
        priceAudit: parse('<span>31000&#8381;</span>'),
        // Content
        consultText: parse('<p>Я&nbsp;с&nbsp;удовольствием поделюсь с&nbsp;вами своим опытом, разберу вашу ситуацию и&nbsp;расскажу о&nbsp;секретах и&nbsp;профессиональных лайфхаках а&nbsp;главное отвечу на&nbsp;вопрос «Что делать?»</p><p><strong>Вам необходима консультация если:</strong></p><ul class="list-consult"><li>Вы&nbsp;хотите создать сайт или landing page, но&nbsp;не&nbsp;знаете с&nbsp;чего начать</li><li>У&nbsp;вас уже есть сайт, но&nbsp;нет клиентов</li><li>Вы&nbsp;только начали бизнес и&nbsp;не&nbsp;понимаете нужен&nbsp;ли вам сайт или приложение или что-то еще</li><li>У&nbsp;вас давно есть своя оффлайн точка, но&nbsp;как заявить о&nbsp;себе в&nbsp;интернете вы&nbsp;смутно представляете, а&nbsp;информации слишком много</li><li>У&nbsp;вас есть собственные разработчики но&nbsp;вы&nbsp;не&nbsp;понимаете чем они занимаются и&nbsp;почему всё так долго</li><li>Вам просто нужно услышать еще одну точку зрения от&nbsp;профессионала</li></ul><p><strong>Всего одна консультация поможет сэкономить несколько сотен тысяч рублей, а&nbsp;так-же впустую потраченные недели и&nbsp;даже месяцы.</strong></p><p>Закажите консультацию и&nbsp;мы&nbsp;договоримся об&nbsp;удобном для вас времени.</p>'),
        consultLink: 'https://forms.gle/pCjhCET8j6A9a5Tw9',
        auditText: parse('<p>Аудит&nbsp;— анализ сайта, его значимых характеристик и&nbsp;функциональности.</p><p><strong>Я&nbsp;провожу комплексный аудит сайта, включая технический, маркетинговый, поисковый и&nbsp;юзабилити.</strong></p><p>Аудит позволяет оценить функциональность и&nbsp;эффективность сайта, выявить слабые и&nbsp;сильные стороны, обнаружить ошибки различного уровня критичности.</p><p>В&nbsp;результате проверки составляется чек-лист необходимых изменений.</p>'),
        auditLink: 'https://forms.gle/Xa1q7kxohg6vLi2r6',
        metricText1: parse('<p>Сегодня самыми известными аналитическими системами для сбора и&nbsp;обработки информации с&nbsp;сайтов и&nbsp;мобильных приложения являются Analytics от&nbsp;Google и&nbsp;Метрика от&nbsp;Яндекса, последняя наиболее популярна в&nbsp;русскоязычных странах. Большинству пользователей хватает возможностей этих систем «из&nbsp;коробки», но&nbsp;для качественной работы нужно потратить немало времени чтобы настроить систему под свои нужды.</p>'),
        metricText2: parse('<p><strong>Правильная настройка аккаунта аналитической системы поможет вам лучше понимать собранную статистику, принимать взвешенные, информированные решения и&nbsp;улучшать результаты работы своего сайта или мобильного приложения.</strong> </p><p>Заказывая у&nbsp;меня услугу по&nbsp;подключению и&nbsp;настройке аналитических систем Я.Метрика и&nbsp;G.Analytics вы&nbsp;получите:</p><ul class="list-consult"><li>Подключение целей и&nbsp;воронок</li><li>Полномасштабную фильтрацию</li><li>Связь с&nbsp;аккаунтами рекламы и&nbsp;вебмастера</li><li>Настройку отчетов и&nbsp;дэшбордов</li></ul><p>И&nbsp;многое другое</p>'),
        metricsLink: 'https://forms.gle/yBdeqqCp45R1QDMq5',
        // Jobs
        job_vk: 'VK',
        date_vk: 'с 12.2022',
        desc_vk: 'Руководитель группы разработки ',
        job_dom: 'ДОМ.РФ',
        date_dom: 'с 05.2021 по 12.2022',
        desc_dom: parse('Руководитель разработки продукта «Аренда»'),
        job_mf: 'MегаФон',
        date_mf: 'с 12.2018 по 05.2021',
        desc_mf: 'Руководитель отдела разработки цифровых решениний департамента инноваций',
        job_syn: 'Университет Синергия',
        date_syn: 'с 10.2014 по 12.2018',
        desc_syn: 'Руководитель отдела интернет разработки',
        job_alp: 'Alpari',
        date_alp: 'с 01.2014 по 10.2014',
        desc_alp: 'Менеджер проектов',
        job_us: 'Ю-Софт',
        date_us: 'с 03.2010 по 12.2013',
        desc_us: 'Руководитель web отдела',
        job_kom: 'Komilfo-Moscow',
        date_kom: 'с 06.2007 по 02.2010',
        desc_kom: 'Разработчик',
        // Pages
        homePageTitle: 'Главная',
        consultPageTitle: 'Консалтинг',
        notFoundPageTitle: 'Not Found'
    },
    en: {
        // Personality
        name: 'Alexandro Odintsov',
        prof1Logo: '💻',
        prof1: 'Head of programmers',
        prof2Logo: '🧔🏻',
        prof2: 'Design and programming of mobile and Web applications',
        prof3Logo: '🦸',
        prof3: 'Consultant on IT projects and products',
        place: 'Moscow, Russia',
        phoneTitle: 'phone',
        mailTitle: 'email',
        // Nav
        navElem1: 'Consultations',
        navElem2: 'G.Analytics&Y.Metrika',
        navElem3: 'Job',
        navElem4: 'Audit',
        // Captions
        caption1: 'Consultations',
        caption2: 'G.Analytics&Y.Metrika',
        caption3: 'My job',
        caption4: 'Before',
        caption5: 'Audit',
        captionBuy: 'Order',
        captionBack: '< back',
        priceAnalytics: parse('<span>from &#36;799.00</span>'),
        //priceConsultBefore: parse('<span>&#36;70.00/hour</span>'),
        priceConsult: parse('<span>&#36;179.00/hour</span>'),
        priceAudit: parse('<span>&#36;350	.00</span>'),
        // Content
        consultText: parse('<p>Just one consultation will help you save several hundred dollars, as&nbsp;well as&nbsp;wasted weeks and even months. Order a&nbsp;consultation and we&nbsp;will arrange a&nbsp;convenient time for you.</p>'),
        consultLink: 'mailto: a@1tsov.com?subject=Consultation',
        auditText: parse('As a result of verification, a checklist of necessary changes is compiled.'),
        auditLink: 'mailto: a@1tsov.com?subject=Audit',
        metricText1: parse('<p>Setting up your Analytics account correctly will help you better understand the collected statistics , make informed decisions, and improve the results of your website or mobile app.</p>'),
        metricText2: ' ',
        metricsLink: 'mailto: a@1tsov.com?subject=Metrics',
            // Jobs
        job_vk: 'VK',
        date_vk: 'from 2022/12',
        desc_vk: 'Head of developers',
        job_dom: 'DOM.RF',
        date_dom: 'from 2021/05 to 2022/12',
        desc_dom: 'Head of developers',
        job_mf: 'MegaFon',
        date_mf: 'from 2018/12 to 2021/05',
        desc_mf: 'Head of developers',
        job_syn: 'Synergy University',
        date_syn: 'form 2014/10 to 2018/12',
        desc_syn: 'Head of developers',
        job_alp: 'Alpari',
        date_alp: 'с 2014/01 по 2014/10',
        desc_alp: 'Project manager',
        job_us: 'Ю-Софт',
        date_us: 'с 2010/03 по 2013/12',
        desc_us: 'Head of developers',
        job_kom: 'Komilfo-Moscow',
        date_kom: 'с 2007/06 по 2010/02',
        desc_kom: 'Developer',
        // Pages
        homePageTitle: 'Home',
        consultPageTitle: 'Consultations',
        notFoundPageTitle: 'Not Found'
    }
};
