import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LangContextConsumer } from '../languages/langContext';
import { getContent } from '../languages/utils';

import Home from '../pages/Home';
import Audit from '../pages/Audit';
import AuditModal from '../components/audit';
import Consult from '../pages/Consult';
import ConsultModal from '../components/consult';
import NotFound from '../pages/NotFound';
import MetricModal from '../components/consult';
import Job from '../pages/Job';
import JobModal from '../components/consult';

export default () => {
    const getComponent = (page, modal) => {
        if (window.innerWidth < 768) {
            console.log(window.innerWidth);
            return page;
        }
        return modal;
    };
    return (
        <LangContextConsumer>
            {(context) => {
                return (
                    <Router>
                        <Switch>
                            <Route exact path="/" title={getContent(context, 'homePageTitle')} component={Home} />
                            <Route
                                exact
                                path="/consult"
                                title={getContent(context, 'consultPageTitle')}
                                children={getComponent(
                                    Consult,
                                    <Home>
                                        <ConsultModal />
                                    </Home>
                                )}
                            />
                            <Route
                                exact
                                path="/job"
                                title={getContent(context, 'jobPageTitle')}
                                children={getComponent(
                                    Job,
                                    <Home>
                                        <JobModal />
                                    </Home>
                                )}
                            />
                            <Route
                                exact
                                path="/audit"
                                title={getContent(context, 'auditPageTitle')}
                                children={getComponent(
                                    Audit,
                                    <Home>
                                        <AuditModal />
                                    </Home>
                                )}
                            />
                            <Route path="*" title={getContent(context, 'notFoundPageTitle')} component={NotFound} />
                        </Switch>
                    </Router>
                );
            }}
        </LangContextConsumer>
    );
};
