import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Link, useHistory } from 'react-router-dom';
import { getContent } from '../../languages/utils';
import Wrapper from '../wrapper';
import Card from '../card';
import { LangContextConsumer } from '../../languages/langContext';

export default ({ inModal = false }) => {
    const history = useHistory();
    useEffect(() => {
        if (inModal) history.replace({ pathname: '/job' });
    }, []);
    return (
        <LangContextConsumer>
            {(context) => (
                <Wrapper sp inModal={inModal}>
                    {!inModal && <Link to={'/'}>{getContent(context, 'captionBack')}</Link>}
                    <h2>{getContent(context, 'caption3')}</h2>
                    <Card
                        {...{
                            title: getContent(context, 'job_vk'),
                            date: getContent(context, 'date_vk'),
                            desc: getContent(context, 'desc_vk')
                        }}
                    />
                    <div style={{ marginTop: 30, height: 1, background: '#979797', flex: 'none' }} />
                    <h2>{getContent(context, 'caption4')}</h2>
                    <Slider
                        {...{
                            slidesToShow: 1,
                            arrows: false,
                            infinite: false,
                            dots: true
                        }}>
                        <Card
                        {...{
                            title: getContent(context, 'job_dom'),
                            date: getContent(context, 'date_dom'),
                            desc: getContent(context, 'desc_dom')
                        }}
                        />  
                        <Card
                            {...{
                                title: getContent(context, 'job_mf'),
                                date: getContent(context, 'date_mf'),
                                desc: getContent(context, 'desc_mf')
                            }}
                        />
                        <Card
                            {...{
                                title: getContent(context, 'job_syn'),
                                date: getContent(context, 'date_syn'),
                                desc: getContent(context, 'desc_syn')
                            }}
                        />
                        <Card
                            {...{
                                title: getContent(context, 'job_alp'),
                                date: getContent(context, 'date_alp'),
                                desc: getContent(context, 'desc_alp')
                            }}
                        />
                        <Card
                            {...{
                                title: getContent(context, 'job_us'),
                                date: getContent(context, 'date_us'),
                                desc: getContent(context, 'desc_us')
                            }}
                        />
                        <Card
                            {...{
                                title: getContent(context, 'job_kom'),
                                date: getContent(context, 'date_kom'),
                                desc: getContent(context, 'desc_kom')
                            }}
                        />
                    </Slider>
                </Wrapper>
            )}
        </LangContextConsumer>
    );
};
