import React from 'react';
import Header from '../components/header';
import Wrapper from '../components/wrapper';
import Main from '../components/main';
import Contacts from '../components/contacts';
import MobileNav from '../components/nav';

import qr from '../components/main/img/qr.svg';

export default () => (
    <>
        <Header />
        <Wrapper>
            <div className="home">
                <div className="home-left">
                    <Main />
                    <Contacts />
                    <MobileNav />
                </div>
                <div className="home-right">
                    <img src={qr} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
            </div>
        </Wrapper>
    </>
);
