import React from 'react';
import './button.scss';
import arrow from './arrow.svg';

export default ({ children, withLogo = false }) => (
    <button className="button">
        {children}
        {withLogo && <img src={arrow} className="button-logo" alt="" />}
    </button>
);
