import React from 'react';
import './contacts.scss';
import { getContent } from '../../languages/utils';
import { LangContextConsumer } from '../../languages/langContext';

export default () => (
    <LangContextConsumer>
        {(context) => (
            <ul className="contacts-list">
{/*
                <li className="contacts-item">
                    <a href="tel:+79252895008">
                        <div className="contacts-title">{getContent(context, 'phoneTitle')}</div>
                        <div className="contacts-link">+7-925-289-50-08</div>
                    </a>
                </li>
*/}
                <li className="contacts-item">
                    <a href="mailto:a@1tsov.com" target="_blank">
                        <div className="contacts-title">{getContent(context, 'mailTitle')}</div>
                        <div className="contacts-link">a@1tsov.com</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//facebook.com/a1tsov" target="_blank">
                        <div className="contacts-title">facebook.com</div>
                        <div className="contacts-link">@a1tsov</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//t.me/a1tsovblog" target="_blank">
                        <div className="contacts-title">telegram.com</div>
                        <div className="contacts-link">@a1tsovblog</div>
                    </a>
                </li>
                <li className="contacts-item">
                    <a href="//blog.1tsov.com" target="_blank">
                        <div className="contacts-title">blog</div>
                        <div className="contacts-link">@blog.1tsov.com</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//github.com/1tsov" target="_blank">
                        <div className="contacts-title">github.com</div>
                        <div className="contacts-link">@1tsov</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//habr.com/users/1tsov/" target="_blank">
                        <div className="contacts-title">habr.com</div>
                        <div className="contacts-link">@1tsov</div>
                    </a>
                </li>
                <li className="contacts-item">
                    <a href="//linkedin.com/in/alexander-odintsov/" target="_blank">
                        <div className="contacts-title">linkedin.com</div>
                        <div className="contacts-link">@alexander-odintsov</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//gitlab.com/1tsov" target="_blank">
                        <div className="contacts-title">gitlab.com</div>
                        <div className="contacts-link">@1tsov</div>
                    </a>
                </li>
                <li className="contacts-item contacts-item--short">
                    <a href="//twitter.com/a1tsov" target="_blank">
                        <div className="contacts-title">twitter.com</div>
                        <div className="contacts-link">@a1tsov</div>
                    </a>
                </li>
            </ul>
        )}
    </LangContextConsumer>
);
