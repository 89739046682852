import React from 'react';
import Header from '../components/header';
import Wrapper from '../components/wrapper';

export default () => (
    <>
        <Header />
        <Wrapper>
            <div style={{ textAlign: 'center', fontSize: 44, fontWeight: 'bold', padding: 25 }}>error 404</div>
        </Wrapper>
    </>
);
