import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import './reboot.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import { LangContextProvider } from './languages/langContext';

ReactDOM.render(
    <LangContextProvider>
        <Router />
    </LangContextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
