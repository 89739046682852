import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LangContextConsumer, LangContextProvider } from '../../languages/langContext';
import './header.scss';
import logo from '../../assets/logo.png';
import { ReactComponent as Sphere } from './sphere.svg';
import { getContent } from '../../languages/utils';
import Modal from '../modal';
import Consult from '../consult';
import Audit from '../audit';
import Jobs from '../jobs';

export default () => {
    const history = useHistory();
    const location = useLocation();
    const context = useContext(LangContextConsumer);

    const [modal, setModal] = useState({ isOpen: false, children: <div>Empty</div> });

    useEffect(() => {
        if (window.innerWidth >= 768)
            switch (location.pathname) {
                case '/consult':
                    setModal({ isOpen: true, children: <Consult context={context} inModal /> });
                    break;
                case '/audit':
                    setModal({ isOpen: true, children: <Audit context={context} inModal /> });
                    break;
                case '/job':
                    setModal({ isOpen: true, children: <Jobs context={context} inModal /> });
                    break;
                default:
                    break;
            }
    }, []);

    const closeModal = () => {
        history.replace({ pathname: '/' });
        setModal((state) => ({ ...state, isOpen: false }));
    };

    return (
        <LangContextConsumer>
            {(context) => (
                <div className="header-wrapper">
                    <Link to="/" className="header-logo">
                        <img src={logo} alt="" />
                        <div>1tsov.com</div>
                    </Link>
                    <nav className="nav">
                        <div
                            onClick={() => setModal({ isOpen: true, children: <Consult context={context} inModal /> })}>
                            {getContent(context, 'navElem1')}
                        </div>
                        <div onClick={() => setModal({ isOpen: true, children: <Jobs context={context} inModal /> })}>
                            {getContent(context, 'navElem3')}
                        </div>
                        <Modal {...{ isOpen: modal.isOpen, closeModal, children: modal.children, whiteBg: true }} />
                    </nav>
                    <div onClick={context.toggleLang} className="header-lang">
                        <Sphere />
                        <div>{context.lang}</div>
                    </div>
                </div>
            )}
        </LangContextConsumer>
    );
};
