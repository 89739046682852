import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getContent } from '../../languages/utils';
import Button from '../button';
import Wrapper from '../wrapper';
import { LangContextConsumer } from '../../languages/langContext';

export default ({ inModal = false }) => {
    const history = useHistory();
    useEffect(() => {
        if (inModal) history.replace({ pathname: '/audit' });
    }, []);
    return (
        <LangContextConsumer>
            {(context) => (
                <Wrapper sp inModal={inModal}>
                    {!inModal && <Link to={'/'}>{getContent(context, 'captionBack')}</Link>}
                    <h2>{getContent(context, 'caption5')}</h2>
                    <div style={{ padding: '0 25px 0 45px', fontSize: 12 }}>{getContent(context, 'auditText')}</div>
                    <div className="price-block">
                        <div className="prev-price">{getContent(context, 'priceAuditBefore')}</div>
                        <div className="price">{getContent(context, 'priceAudit')}</div>
                        <a href={getContent(context, 'auditLink')} target="_blank">
                            <Button withLogo>{getContent(context, 'captionBuy')}</Button>
                        </a>
                    </div>
                </Wrapper>
            )}
        </LangContextConsumer>
    );
};
