import React from 'react';
import Header from '../components/header';
import Consult from '../components/consult';

export default () => (
    <>
        <Header />
        <Consult />
    </>
);
