import React, { useState } from 'react';
import detectBrowserLanguage from 'detect-browser-language';
const { Provider, Consumer } = React.createContext();

const LangContextProvider = ({ children }) => {
    const [lang, setLang] = useState(detectBrowserLanguage().split('-')[0]);

    const toggleLang = () => setLang(lang === 'ru' ? 'en' : 'ru');

    return <Provider value={{ lang, toggleLang }}>{children}</Provider>;
};

export { LangContextProvider, Consumer as LangContextConsumer };
