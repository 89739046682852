import React, { useState } from 'react';
import Modal from '../modal';
import './main.scss';
import photo from './img/photo.jpg';
import qr from './img/qr.svg';
import { ReactComponent as QrLink } from './img/qr-link.svg';
import { LangContextConsumer } from '../../languages/langContext';
import { getContent } from '../../languages/utils';

export default () => {
    const [modal, setModal] = useState(false);
    const closeModal = () => setModal(false);
    return (
        <LangContextConsumer>
            {(context) => {
                return (
                    <div className="main">
                        <div className="main-top">
                            <div className="main-photo">
                                <img src={photo} alt="" />
                            </div>
                            <div className="main-name">{getContent(context, 'name')}</div>
                            <QrLink className="main-qr-link" onClick={() => setModal(true)} />
                            <Modal {...{ isOpen: modal, closeModal }}>
                                <div className="main-qr">
                                    <img style={{ maxWidth: '100%' }} src={qr} alt="QR" />
                                </div>
                            </Modal>
                        </div>
                        <ul className="main-desc">
                            <li className="main-text">
                                <span>{getContent(context, 'prof1Logo')}</span>
                                <span>{getContent(context, 'prof1')}</span>
                            </li>
                            <li className="main-text">
                                <span>{getContent(context, 'prof2Logo')}</span>
                                <span>{getContent(context, 'prof2')}</span>
                            </li>
                            <li className="main-text">
                                <span>{getContent(context, 'prof3Logo')}</span>
                                <span>{getContent(context, 'prof3')}</span>
                            </li>
                        </ul>
                        <div className="main-place">{getContent(context, 'place')}</div>
                    </div>
                );
            }}
        </LangContextConsumer>
    );
};
